.Header {
  color: #FFFFFF;
  height: 65px;
  padding: 10px;
  display: block;
  user-select: none;
}

.LargeText {
  font-size: 3em;
  text-transform: lowercase;
}


.InvisibleHeader {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media (max-width: 600px) {
  .Header {
    padding-top: 75px;
  }
}

.RainbowText {
  background: linear-gradient(60deg,
        #E40303 0%,
        #FF8C00 5.56%,
        #FFED00 11.11%,
        #008026 16.66%,
        #24408E 22.22%,
        #732982 27.78%,
        #E40303 33.33%,
        #FF8C00 38.89%,
        #FFED00 44.44%,
        #008026 50%,
        #24408E 55.56%,
        #732982 61.11%,
        #E40303 66.66%,
        #FF8C00 72.22%,
        #FFED00 77.78%,
        #008026 83.33%,
        #24408E 88.89%,
        #732982 94.44%,
        #E40303 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.2);
  animation: 4.5s slide linear infinite;
  background-size: 300%;
  background-position-x: 25%;
}

@keyframes slide {
  0% {
    background-position-x: 25%;
  }

  /*50% {
    background-position-x: 66.67%;
  }*/

  100% {
    background-position-x: 77%;
  }
}