.MenuWrapper {
    position: fixed;
    top: 80px;
    right: -20px;
    background-color: #2D2D2A;
    border: solid 1px white;
    padding: 10px;
    border-radius: 10px;
    font-size: 1.3em;
    display: block;
    text-align: right;
    transition: right .5s;
    user-select: none;
}

.MenuWrapper.MenuHidden {
    right: -140px;
    box-shadow: none;
}

.MenuWrapper ul li {
    list-style-type: none;
    margin-bottom: 2px;
    margin-top: 2px;
}

.MenuWrapper ul {
    margin: 0;
    padding: 0;
    padding-right: 35px;
}