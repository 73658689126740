
.CardFlowWrapper {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 20%;
}

.CardFlowWrapper .CardWrapper {
    width: 30%;
    border: solid white 1px;
    border-radius: 8px;
    overflow: clip;
    padding-bottom: 20px;
    background-color: #0B2632;
    min-width: 200px;
    margin-bottom: 20px;
}

.CardFlowWrapper .CardWrapper h3, .CardFlowWrapper .CardWrapper p {
    padding-right: 5px;
    padding-left: 5px;
    text-transform: none;
}

.CardFlowWrapper .LinkContainer {
    display: flex;
    justify-content: space-around;
}

.CardFlowWrapper .LinkContainer a {
    display: block;
    height: 1.8em;
    line-height: 1.8em;
    width: 70px;
    border: solid #999999 1px;
    border-radius: 4px;
    text-decoration: none;
    background-color: #000000;
}

.CardFlowWrapper img {
    width: 100%;
}