.MenuButtonWrapper {
    position: fixed;
    top: 20px;
    right: 20px;
}

.MenuButtonWrapper button {
    background-color: #2D2D2A;
    border: solid 1px #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 1.3em;
    margin-left: auto;
    margin-right: auto;
    display: block;
    transition: box-shadow 500ms;
    z-index: 2;
}

.MenuButtonWrapper button:hover {
    cursor: pointer;
    z-index: 1;
}

.MenuButtonWrapper button div.HorizontalLine {
    background-color: #FFFFFF;
    height: 2px;
    width: 20px;
    display: block;
}

.MenuButtonWrapper button div.HorizontalLineWrapper {
    display: flex;
    flex-direction: column;
    height: 20px;
    width: 20px;
    justify-content: space-around;
}

@keyframes dance {
     0% {transform: rotate(0deg);}
    25% {transform: rotate(-5deg);}
    75% {transform: rotate(5deg);}
    100% {transform: rotate(0deg);}
}

.MenuButtonWrapper button:hover div.HorizontalLineWrapper {
    animation-name: dance;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}