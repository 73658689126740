.App {
  text-align: center;
  background-color: #2d2d2a;
  background: linear-gradient(135deg, #000000 0%, #2d2d2a 50%, #000000 100%);
  margin: 0;
  padding-bottom:30px;
  color: #FFFFFF;
}

h2, h3 {
  text-transform: lowercase;
  text-align: center;
}

h2 {
  font-size: 2em;
}
h3 {
  font-size: 1.5em;
}

a, a.visited {
  color: #C14953;
}

a.hover {
  color: #FFCACC;
}

hr {
  color: #999999;
}
