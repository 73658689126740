
.ContactWrapper {
    margin: 20px 20%;
}

.ContactWrapper img {
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 5px;
    margin: 5px;
}

.ContactWrapper a.LinkedInWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}