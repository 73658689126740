.TextPhotoWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: row;
}

.TextPhotoWrapper div {
    width: 70%;
}

.TextPhotoWrapper p,
.TextPhotoWrapper ul li {
    text-align: left;
}

.TextPhotoWrapper img {
    width: 20%;
    min-width: 200px;
    margin-left: 10px;
}

.TextPhotoWrapper h3 {
    clear: both;
}

@media (max-width: 600px) {
    .TextPhotoWrapper {
        flex-direction: column;
        width: 100%;
    }

    .TextPhotoWrapper p {
        order: 2;
        width: 90%;
        margin: auto;
        margin-bottom: 10px;
    }

    .TextPhotoWrapper img {
        order: 1;
        width: 60%;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        
    }
}
